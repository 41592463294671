// import { SaleorLogo } from "@/checkout/images";
import Stamp from "./Stamp";

const PageHeader = () => {
  return (
    <div className="page-header">
      <Stamp />
    </div>
  );
};

export default PageHeader;
