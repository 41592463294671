import { Divider } from "@/checkout/components/Divider";
import { useCheckout } from "@/checkout/hooks/useCheckout";
import { Contact, ContactSkeleton } from "@/checkout/sections/Contact";
import {
  ShippingMethods,
  ShippingMethodsSkeleton,
} from "@/checkout/sections/ShippingMethods";
import { Addresses, AddressesSkeleton } from "@/checkout/sections/Addresses";
import { useErrorMessages } from "@/checkout/hooks/useErrorMessages";
import { useValidationResolver } from "@/checkout/lib/utils";
import { Suspense } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { object, string } from "yup";
import { Button } from "@/checkout/components/Button";
import { useCheckoutFinalize } from "./useCheckoutFinalize";
import { FormData } from "./types";
import { useFormattedMessages } from "@/checkout/hooks/useFormattedMessages";
import { useAuthState } from "@saleor/sdk";
import "./CheckoutFormStyles.css";
import { useSetFormErrors } from "@/checkout/hooks/useSetFormErrors";
import { usePaymentMethods } from "../PaymentMethods/usePaymentMethods";
import { PaymentMethods } from "../PaymentMethods";
import { PaymentMethodID, PaymentProviderID } from "@/checkout-app/types";

export const CheckoutForm = () => {
  const formatMessage = useFormattedMessages();
  const { errorMessages } = useErrorMessages();
  const { checkout, loading } = useCheckout();
  const { authenticating } = useAuthState();
  const {
    checkoutFinalize,
    submitting,
    errors: userRegisterErrors,
  } = useCheckoutFinalize();

  const isLoading = loading || authenticating;
  // const usePaymentProvidersProps = usePaymentMethods(checkout?.channel?.id);
  // const { selectedPaymentProvider } = usePaymentProvidersProps;

  const selectedPaymentProvider = "razorpay";

  const schema = object({
    password: string().required(errorMessages.required),
    email: string()
      .email(errorMessages.invalid)
      .required(errorMessages.required),
  });

  const resolver = useValidationResolver(schema);
  // will be used for e.g. account creation at checkout finalization
  const methods = useForm<FormData>({
    resolver,
    mode: "onBlur",
    defaultValues: { email: checkout?.email || "", createAccount: false },
  });

  useSetFormErrors({
    setError: methods.setError,
    errors: userRegisterErrors,
  });

  const { getValues } = methods;

  const windowObject = window;
  // //hard coded
  // if (checkout) {
  //   usePaymentProvidersProps?.setSelectedPaymentMethod(
  //     "mirumee.payments.razorpay" as PaymentMethodID
  //   );
  // }
  // not using form handleSubmit on purpose
  const handleSubmit = () =>
    checkoutFinalize(
      {
        ...getValues(),
        paymentProviderId: selectedPaymentProvider as PaymentProviderID,
      },
      makePayment
    );

  const payButtonDisabled =
    submitting ||
    (checkout?.isShippingRequired && !checkout?.shippingAddress) ||
    !checkout?.billingAddress ||
    !selectedPaymentProvider;

  // console.log(
  //   submitting,
  //   (checkout?.isShippingRequired && !checkout?.shippingAddress) ||
  //     !checkout?.billingAddress ||
  //     !selectedPaymentProvider,
  //   "submitting"
  // );

  const makePayment = async (data: any, callback: Function) => {
    console.log("makePayment...");
    const res = await initializeRazorpay();

    if (!res) {
      console.log("Razorpay SDK Failed to load");
      return;
    }

    // Make API call to the serverless API
    // const data = await fetch("/api/razorpay", { method: "POST" }).then((t) =>
    //   t.json()
    // );
    console.log(data);
    var options = {
      key: process.env.RAZORPAY_KEY, // Enter the Key ID generated from the Dashboard
      // name: "Test name",
      currency: data.currency,
      amount: data.amount.toString(),
      order_id: data.id,
      // description: "Thankyou for your test",
      image: "",
      handler: function (response: {
        razorpay_payment_id: number;
        razorpay_order_id: number;
        razorpay_signature: string;
      }) {
        // Validate payment at server - using webhooks is a better idea.
        // alert(response.razorpay_payment_id);
        // alert(response.razorpay_order_id);
        // alert(response.razorpay_signature);
        callback(response);
      },
      prefill: {
        // name: "Test",
        email: checkout?.email || "",
      },
    };

    const paymentObject = new (windowObject as any).Razorpay(options);
    paymentObject.on("payment.failed", function (response: any) {
      console.log(response, "payment.failed");
    });
    paymentObject.open();
  };

  const initializeRazorpay = () => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      // document.body.appendChild(script);

      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };

      document.body.appendChild(script);
    });
  };

  return (
    <div className="checkout-form">
      <FormProvider {...methods}>
        {isLoading ? <ContactSkeleton /> : <Contact />}
      </FormProvider>
      <Divider className="mt-4" />
      <Suspense fallback={<AddressesSkeleton />}>
        {isLoading ? <AddressesSkeleton /> : <Addresses />}
      </Suspense>
      <Suspense fallback={<ShippingMethodsSkeleton />}>
        {isLoading ? <ShippingMethodsSkeleton /> : <ShippingMethods />}
      </Suspense>
      {/* <PaymentMethods {...usePaymentProvidersProps} /> */}
      {isLoading ? (
        <Button
          ariaLabel={formatMessage("finalizeCheckoutLabel")}
          label=""
          className="pay-button"
        />
      ) : (
        <Button
          disabled={payButtonDisabled}
          ariaLabel={formatMessage("finalizeCheckoutLabel")}
          label="Pay"
          onClick={handleSubmit}
          className="pay-button"
        />
      )}
    </div>
  );
};
