import { BrandingColors } from "./types";

export const STYLE_ELEMENT_ID = "appConfigStyle";

export const defaultAppColors: BrandingColors = {
  buttonBgColorPrimary: "#394052",
  buttonBgColorHover: "#FFFFFF",
  borderColorPrimary: "#394052",
  textColor: "#394052",
  successColor: "#2C9B2A",
  errorColor: "#B65757",
  buttonTextColor: "#FFFFFF",
};
