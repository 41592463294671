import { pay as payRequest, PaySuccessResult, razorpayHook } from "@/checkout/fetch";
import { useFetch } from "@/checkout/hooks/useFetch";
import { OrderBody, CheckoutBody } from "checkout-app/types/api/pay";

const getRedirectUrl = () => {
  const url = new URL(window.location.href);
  const redirectUrl = url.searchParams.get("redirectUrl");

  // get redirectUrl from query params (passed from storefront)
  if (redirectUrl) {
    return redirectUrl;
  }

  // return existing url without any search params
  return location.origin + location.pathname;
};

export const usePay = () => {
  const [{ loading }, pay] = useFetch(payRequest, { skip: true });

  const checkoutPay = async ({
    provider,
    checkoutId,
    totalAmount,
  }: Omit<CheckoutBody, "redirectUrl">, callback?: Function) => {
    const redirectUrl = getRedirectUrl();
    const result = await pay({
      provider,
      checkoutId,
      totalAmount,
      redirectUrl,
    });
    
    console.log(result, "result usePay.ts");
    if ((result as PaySuccessResult)?.data?.paymentUrl) {
      console.log(result, "result usePay.ts");
      const {
        orderId,
        data: { paymentUrl },
        id,
        currency,
        amount
      } = result as PaySuccessResult;
      callback?.call(this, {
        id,
        currency,
        amount
      }, async (makePaymentResponse:any)=>{
        console.log(makePaymentResponse, orderId, "success callback usePay.ts");

        let r = await razorpayHook({
          id: orderId,
          razorpay_payment_id: makePaymentResponse.razorpay_payment_id,
        razorpay_order_id: makePaymentResponse.razorpay_order_id,
        razorpay_signature: makePaymentResponse.razorpay_signature,
          currency: currency as string,
        amount: amount as string,
        });
        // r.then(()=>{

        // });

        if(r){
          let rJson = await r.json();

          console.log(rJson,"rJson");
          // if(rJson && rJson.status === "SUCCESS"){
            const newUrl = `?order=${orderId}`;
            window.location.href = newUrl;
          // }
          
        }

        
        // window.history.replaceState(
        //   { ...window.history.state, as: newUrl, url: newUrl },
        //   "",
        //   newUrl
        // );
       
      })
      
    }

    return result;
  };

  const orderPay = async ({
    provider,
    orderId,
  }: Omit<OrderBody, "redirectUrl">) => {
    const redirectUrl = getRedirectUrl();
    const result = await pay({
      provider,
      orderId,
      redirectUrl,
    });

    if ((result as PaySuccessResult)?.data?.paymentUrl) {
      window.location.href = (result as PaySuccessResult).data.paymentUrl;
    }

    return result;
  };

  return { orderPay, checkoutPay, loading };
};
