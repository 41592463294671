interface StampProps {
  width?: number;
  height?: number;
}

function Stamp({ width = 137, height = 29 }: StampProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_137_430)">
        <path d="M29.9847 0H0V10.1864H9.72293V29H20.2618V10.1864H29.9847V0Z" fill="#2960F7" />
        <path
          d="M55.9376 0.010498V9.41333H45.4015V0.010498H35.6785L35.6758 19.5997H45.3987V28.9999H55.9349V19.5997H65.6605V0.010498H55.9376Z"
          fill="#2960F7"
        />
        <path
          d="M101.332 0H71.3477V29H101.332V0ZM90.7935 18.8136H81.8838V10.1864H90.7935V18.8136Z"
          fill="#2960F7"
        />
        <path d="M107.016 0L107.018 29H117.557V10.1864H126.464V29H137V0H107.016Z" fill="#2960F7" />
      </g>
      <defs>
        <clipPath id="clip0_137_430">
          <rect width="137" height="29" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Stamp;
